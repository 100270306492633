<script setup lang="ts">
const nuxtApp = useNuxtApp()
const { $i18n } = nuxtApp
const runtimeConfig = useRuntimeConfig()

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${$i18n.t('siteTitle')}` : `${$i18n.t('siteTitle')}`
  },
  script: [
    {
      src: 'https://unpkg.com/large-small-dynamic-viewport-units-polyfill@0.0.4/dist/large-small-dynamic-viewport-units-polyfill.min.js'
    },
    {
      hid: "INITIATE_RELIC",
      src: "/relic.js",
      defer: true,
      type: "text/javascript"
    },
  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', href: `${runtimeConfig?.public?.baseUrl}/favicon/favicon-black.ico`, media: "(prefers-color-scheme: light)" },
    { rel: 'icon', type: 'image/x-icon', href: `${runtimeConfig?.public?.baseUrl}/favicon/favicon.ico`, media: "(prefers-color-scheme: dark)" },
  ]
})
</script>

<template>
  <div id="app">
    <NuxtLayout>
      <NavigationLoadingIndicatorComponent />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
