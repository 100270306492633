import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoNestPen, LazySvgoNewsletterSignUp, LazySvgoArrow, LazySvgoChessboard, LazySvgoCross, LazySvgoEnvelope, LazySvgoLogoAnimated, LazySvgoLogoMondriaan, LazySvgoLogoNest2, LazySvgoLogoNest3, LazySvgoLogoNest, LazySvgoLogoTheHague, LazySvgoMap, LazySvgoMessage, LazySvgoMinus, LazySvgoNestNow, LazySvgoNestNu, LazySvgoPlus } from '#components'
const lazyGlobalComponents = [
  ["SvgoNestPen", LazySvgoNestPen],
["SvgoNewsletterSignUp", LazySvgoNewsletterSignUp],
["SvgoArrow", LazySvgoArrow],
["SvgoChessboard", LazySvgoChessboard],
["SvgoCross", LazySvgoCross],
["SvgoEnvelope", LazySvgoEnvelope],
["SvgoLogoAnimated", LazySvgoLogoAnimated],
["SvgoLogoMondriaan", LazySvgoLogoMondriaan],
["SvgoLogoNest2", LazySvgoLogoNest2],
["SvgoLogoNest3", LazySvgoLogoNest3],
["SvgoLogoNest", LazySvgoLogoNest],
["SvgoLogoTheHague", LazySvgoLogoTheHague],
["SvgoMap", LazySvgoMap],
["SvgoMessage", LazySvgoMessage],
["SvgoMinus", LazySvgoMinus],
["SvgoNestNow", LazySvgoNestNow],
["SvgoNestNu", LazySvgoNestNu],
["SvgoPlus", LazySvgoPlus],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
