import { default as _91slug_93EZdZHHI0dkMeta } from "/workspace/nuxt/pages/[slug].vue?macro=true";
import { default as _91slug_9343o2EzgqAwMeta } from "/workspace/nuxt/pages/education/[slug].vue?macro=true";
import { default as educationq0RdKQO5jIMeta } from "/workspace/nuxt/pages/education.vue?macro=true";
import { default as _91slug_933XnHeyE3RVMeta } from "/workspace/nuxt/pages/events/[slug].vue?macro=true";
import { default as eventsw3f86XFGfaMeta } from "/workspace/nuxt/pages/events.vue?macro=true";
import { default as documentationM4BYAnobj0Meta } from "/workspace/nuxt/pages/exhibitions/[slug]/documentation.vue?macro=true";
import { default as index4bOFYEOK5cMeta } from "/workspace/nuxt/pages/exhibitions/[slug]/index.vue?macro=true";
import { default as indexsrNo3ECWPhMeta } from "/workspace/nuxt/pages/exhibitions/index.vue?macro=true";
import { default as indexYicQbbOvh5Meta } from "/workspace/nuxt/pages/index.vue?macro=true";
import { default as _91slug_93JdRV63TApNMeta } from "/workspace/nuxt/pages/journals/[slug].vue?macro=true";
import { default as index4JlIMjUeN8Meta } from "/workspace/nuxt/pages/journals/index.vue?macro=true";
import { default as newsletterrSPdQGYG3oMeta } from "/workspace/nuxt/pages/newsletter.vue?macro=true";
import { default as page_45previewn2wjD0GievMeta } from "/workspace/nuxt/pages/page-preview.vue?macro=true";
import { default as presslGXyP6smzwMeta } from "/workspace/nuxt/pages/press.vue?macro=true";
import { default as _91slug_93flmdvyuEBJMeta } from "/workspace/nuxt/pages/projects/[slug].vue?macro=true";
import { default as projectsGWmAJ9AO9fMeta } from "/workspace/nuxt/pages/projects.vue?macro=true";
import { default as component_45stubRotpj5dKP6Meta } from "/workspace/nuxt/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRotpj5dKP6 } from "/workspace/nuxt/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___nl",
    path: "/:slug()",
    component: () => import("/workspace/nuxt/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/en/:slug()",
    component: () => import("/workspace/nuxt/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "education___nl",
    path: "/education",
    component: () => import("/workspace/nuxt/pages/education.vue").then(m => m.default || m),
    children: [
  {
    name: "education-slug___nl",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/education/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "education___en",
    path: "/en/education",
    component: () => import("/workspace/nuxt/pages/education.vue").then(m => m.default || m),
    children: [
  {
    name: "education-slug___en",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/education/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "events___nl",
    path: "/events",
    component: () => import("/workspace/nuxt/pages/events.vue").then(m => m.default || m),
    children: [
  {
    name: "events-slug___nl",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/events/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "events___en",
    path: "/en/events",
    component: () => import("/workspace/nuxt/pages/events.vue").then(m => m.default || m),
    children: [
  {
    name: "events-slug___en",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/events/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "exhibitions-slug-documentation___nl",
    path: "/exhibitions/:slug()/documentation",
    component: () => import("/workspace/nuxt/pages/exhibitions/[slug]/documentation.vue").then(m => m.default || m)
  },
  {
    name: "exhibitions-slug-documentation___en",
    path: "/en/exhibitions/:slug()/documentation",
    component: () => import("/workspace/nuxt/pages/exhibitions/[slug]/documentation.vue").then(m => m.default || m)
  },
  {
    name: "exhibitions-slug___nl",
    path: "/exhibitions/:slug()",
    component: () => import("/workspace/nuxt/pages/exhibitions/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "exhibitions-slug___en",
    path: "/en/exhibitions/:slug()",
    component: () => import("/workspace/nuxt/pages/exhibitions/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "exhibitions___nl",
    path: "/exhibitions",
    component: () => import("/workspace/nuxt/pages/exhibitions/index.vue").then(m => m.default || m)
  },
  {
    name: "exhibitions___en",
    path: "/en/exhibitions",
    component: () => import("/workspace/nuxt/pages/exhibitions/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/workspace/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/workspace/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "journals-slug___nl",
    path: "/journals/:slug()",
    component: () => import("/workspace/nuxt/pages/journals/[slug].vue").then(m => m.default || m)
  },
  {
    name: "journals-slug___en",
    path: "/en/journals/:slug()",
    component: () => import("/workspace/nuxt/pages/journals/[slug].vue").then(m => m.default || m)
  },
  {
    name: "journals___nl",
    path: "/journals",
    component: () => import("/workspace/nuxt/pages/journals/index.vue").then(m => m.default || m)
  },
  {
    name: "journals___en",
    path: "/en/journals",
    component: () => import("/workspace/nuxt/pages/journals/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletter___nl",
    path: "/newsletter",
    meta: newsletterrSPdQGYG3oMeta || {},
    component: () => import("/workspace/nuxt/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "newsletter___en",
    path: "/en/newsletter",
    meta: newsletterrSPdQGYG3oMeta || {},
    component: () => import("/workspace/nuxt/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "page-preview",
    path: "/page-preview",
    component: () => import("/workspace/nuxt/pages/page-preview.vue").then(m => m.default || m)
  },
  {
    name: "press___nl",
    path: "/press",
    component: () => import("/workspace/nuxt/pages/press.vue").then(m => m.default || m)
  },
  {
    name: "press___en",
    path: "/en/press",
    component: () => import("/workspace/nuxt/pages/press.vue").then(m => m.default || m)
  },
  {
    name: "projects___nl",
    path: "/projects",
    component: () => import("/workspace/nuxt/pages/projects.vue").then(m => m.default || m),
    children: [
  {
    name: "projects-slug___nl",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/projects/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "projects___en",
    path: "/en/projects",
    component: () => import("/workspace/nuxt/pages/projects.vue").then(m => m.default || m),
    children: [
  {
    name: "projects-slug___en",
    path: ":slug()",
    component: () => import("/workspace/nuxt/pages/projects/[slug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: component_45stubRotpj5dKP6Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubRotpj5dKP6
  }
]